import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';

//Carousel 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment'

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import api from '../api';
import Loader from '../component/Loader';
import { toast } from 'react-toastify';
const MyOrder = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState(null);


    useEffect(() => {
        if (datas == null) {
            (async () => {
                await loadData();
            })();
        }
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        let uid = localStorage.getItem('uid');

        await api.get('api/rest/scan-2-order/get-my-scan-2-order?uid=' + uid, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.status === 200) { 
                    setDatas(response.data.data);
                }
            })
            .catch(error => {
                toast.error(error.response.data);
            }).finally(() => {
                setIsLoading(false);
            });
    }


    return (
        <React.Fragment>
            <Container>
                <Row>
                    <br />
                    <br />
                    <br />
                    <br />
                </Row>
                <Row>
                    {isLoading && <Loader />}
                    <Button onClick={loadData}>Refresh</Button>
                    {
                        datas && datas.map((data, key) => (
                            <Col lg={3} key={key}>
                                <Card style={{ marginTop: "10px" }}>
                                    <CardContent>
                                        <strong style={{ color: 'firebrick' }}>{data.status}</strong><span style={{ fontSize: 12 }}> ({data.type})</span>
                                        <br />
                                        <span style={{ fontSize: 12 }}>Ordered@</span><span style={{ fontSize: 10 }}>{moment(data.requestedAt).format("DD-MMM-YYYY HH:mm:ss A")}</span>
                                        <br />
                                        <span style={{ fontSize: 12 }}>Responded@</span><span style={{ fontSize: 10 }}>{data.responseAt == null ? 'Waiting for response' : moment(data.responseAt).format("DD-MMM-YYYY HH:mm:ss A")}</span>
                                        <br />
                                        <span style={{ fontSize: 10 }}>{data.remarks}</span>


                                        <ul className="">
                                            {

                                                JSON.parse(data.jsoncontent).items && JSON.parse(data.jsoncontent).items.map((item, key) => (
                                                    <li className="border-bottom d-flex justify-content-between mb-10" key={key}>
                                                        <div className="media overflow-hidden">
                                                            <div className="mr-15">
                                                                {/* <img src={cart.image} alt="products" className="media-object" width="63" height="63" /> */}
                                                            </div>
                                                            <div className="">
                                                                <span><small><b>{item.itemName}</b></small></span>
                                                                <br />
                                                                <small className='text-muted'>  Rs. : {item.mrp} * {item.qty}</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginRight: 50 }}>
                                                            <span><small><b>{item.qty * item.mrp}</b></small></span>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        {data.type == "KOT"
                                            ?
                                            <ul>
                                                <li className="border-bottom d-flex justify-content-between pt-2 pb-2">
                                                    <div className="media overflow-hidden">
                                                        <div className="mr-15">

                                                        </div>
                                                        <div className="media-body text-truncate">

                                                        </div>
                                                    </div>
                                                    <div className="w-10">

                                                    </div>
                                                    <div className="w-15">
                                                        <span className="text-muted fs-12 d-block mb-10">Total</span>
                                                    </div>
                                                    <div style={{ marginRight: 50 }}>
                                                        <span className="font-weight-bold">
                                                            {JSON.parse(data.jsoncontent).items && JSON.parse(data.jsoncontent).items.reduce((sum, b) => sum += (b.mrp * b.qty), 0)}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            :
                                            null
                                        }

                                    </CardContent>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </React.Fragment>
    );
};
export default MyOrder;
