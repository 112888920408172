import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import FooterLink from './FooterLink';
import ViewCart from './ViewCart';

const Footer = () => {
    return (
        <React.Fragment> 
            {/* <FooterLink /> */}
            <ViewCart />
        </React.Fragment>
    );
};
export default Footer;
