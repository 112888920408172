import React,{useEffect, useState} from 'react';
import { Container, Row, Col } from 'reactstrap';
 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
import Meals from "./meal/Meals";

export default function Menu(params) { 
    return (
        <React.Fragment>
            <Container>
                <br />
                <Row>
                    <Col lg={12}>
                        <div className="mt-5 pt-4">
                            <h3 className="fw-light text-uppercase">Menu</h3>
                            <div className="title-border-color position-relative"></div>
                        </div>
                    </Col>
                </Row>
                    
                <Row>  
                    <Meals />                         
                </Row>
            </Container> 
        </React.Fragment>
    );
};
