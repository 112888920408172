import React from "react";

const CartContext = React.createContext({
    allItems: [],
    filteredItems: [],
    items: [],
    totalAmount: 0,
    customerInfo: { name: '', mobile: '', email: '', secretKey: '', otp: '' },
    addItem: (item) => { },
    substractItem: (item) => { },
    removeItem: (productChildId) => { },
    clearItems: () => { },
    setAllItems: (items) => [],
    setFilteredItems: (items) => [],
    setCustomerInfo: (customerInfo) => { }
});

export default CartContext;
