import React, { useEffect, useState, useContext, useRef } from 'react'
import styled from "styled-components";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'
import api from '../../api';
import CartContext from "../../store/CartContext";
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';
const StyledCart = styled.div`
    .mobileCart {
        position: fixed;
        bottom: 0px;
        width: 100%;
        display: none;
        background-color: #ffffff; 
    }
    @media (max-width: 768px) {
        .mobileCart {
            display: block;
        }
    }
`;

const ViewCart = () => {
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const location = useLocation();
    const [success, setSuccess] = useState(false);
    const [path, setPath] = useState("/");
    const deleteConfirmationDialog = useRef(null);
    const [loading, setLoading] = useState(false);

    const onChangeCustomerInfo = (key, value) => {
        var cinfo = cartCtx.customerInfo;
        if (key == "name")
            cinfo.name = value;
        else if (key == "mobile")
            cinfo.mobile = value;
        else if (key == "email")
            cinfo.email = value;
        else if (key == "secretkey")
            cinfo.secretKey = value;
        else if (key == "otp")
            cinfo.otp = value;

        cartCtx.setCustomerInfo(cinfo);
    }

    useEffect(() => {
        setPath(location.pathname);
    }, [location])

    const onConfirm = () => {
        setSuccess(false);
    }

    console.log(cartCtx.customerInfo);
    console.log(path);

    const placeOrder = () => {
        if (localStorage.getItem('tableName') == 0) {
            alert("You are not authorized to place order !");
            return;
        }
        if (cartCtx.items.length == 0) {
            toast('Your cart is empty !');
            navigate("/");
            return;
        }

        if (localStorage.getItem('token') == null || localStorage.getItem('token') == undefined) {
            navigate('/request-otp')
            return;
        }

        deleteConfirmationDialog.current.open();
    }

    const finallyPlaceOrder = async () => {
        deleteConfirmationDialog.current.close();
        var data = {
            items: cartCtx.items,
            tableName: localStorage.getItem('tableName'),
            tenantId: localStorage.getItem('restaurantId'),
            branchId: localStorage.getItem('branchId')
        }

        var newData = {
            jsonContent: JSON.stringify(data),
            tableNo: localStorage.getItem('tableName'),
            type: "KOT",
            branchId: localStorage.getItem('branchId')
        }
         console.log(JSON.stringify(newData));
        // console.log(localStorage.getItem('token'));
        setLoading(true);
        api.post('api/rest/scan-2-order/post-scan-2-order', newData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                if (response.status == 200) {
                    setSuccess(true);
                    cartCtx.clearItems();
                    navigate("/");
                    //window.location.href = "/";
                } else {
                    alert("Something went wrong !");
                }
            })
            .catch(error => {
                toast.error(error.response.data);
                //alert(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    }

    const requestOTP = () => {
        if (cartCtx.customerInfo.mobile.length != 10) {
            toast('Enter mobile to continue !');
            return;
        }
        var newData = {
            tenantID: localStorage.getItem('restaurantId'),
            mobile: cartCtx.customerInfo.mobile,
            checkInUserMaster:false
        }

        console.log(newData);

        setLoading(true);
        api.post('api/token/generate-otp-client-token', newData)
            .then(async (response) => {
                toast('OTP sent to your mobile !');
                onChangeCustomerInfo('secretkey', response.data);
                navigate('/verify-otp');
            })
            .catch(error => {
                alert(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    }

    const verifyOTP = () => {
        if (cartCtx.customerInfo.otp.length == 0 || cartCtx.customerInfo.otp == undefined || cartCtx.customerInfo.otp.length != 4) {
            toast.error("Enter OTP to continue..");
            return
        }
        var newData = {
            tenantID: localStorage.getItem('restaurantId'),
            mobile: cartCtx.customerInfo.mobile,
            otp: cartCtx.customerInfo.otp,
            secretKey: cartCtx.customerInfo.secretKey,
        }

        console.log(newData);

        setLoading(true);
        api.post('api/token/get-client-token-using-otp', newData)
            .then(async (response) => {
                localStorage.setItem('token', response.data.token);
                onChangeCustomerInfo('otp', '');
                onChangeCustomerInfo('secretkey', '');
                toast.success("You are now logged in");
                navigate('checkout');
            })
            .catch(error => {
                toast.error(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    }

    function renderButton(path) {
        console.log(localStorage.getItem('tableName'))
        console.log(localStorage.getItem('tableName') != 0)
        switch (path) {
            case '/checkout':
                return <button onClick={() => placeOrder()} className='btn btn-danger col-12' disabled={loading}>{loading ? 'Please wait...' : 'Place Order'}</button>
            case '/':
                return cartCtx.items.length > 0 && localStorage.getItem('tableName') != 0 ? <Link to='/checkout' className='btn btn-danger col-12' disabled={loading}>{loading ? 'Please wait...' : 'Checkout'}</Link> : null
            case '/menu':
                return cartCtx.items.length > 0 && localStorage.getItem('tableName') != 0 ? <Link to='/checkout' className='btn btn-danger col-12' disabled={loading}>{loading ? 'Please wait...' : 'Checkout'}</Link> : null
            case '/cart':
                return cartCtx.items.length > 0 && localStorage.getItem('tableName') != 0 ? <Link to='/checkout' className='btn btn-danger col-12' disabled={loading}>{loading ? 'Please wait...' : 'Checkout'}</Link> : null
            case '/request-otp':
                return <button onClick={() => requestOTP()} className='btn btn-danger col-12' disabled={loading}>{loading ? 'Please wait...' : 'Request OTP'}</button>
            case '/verify-otp':
                return <button onClick={() => verifyOTP()} className='btn btn-primary col-12' disabled={loading}>{loading ? 'Please wait...' : 'Verify OTP'}</button>
            default:
                return cartCtx.items.length > 0 && localStorage.getItem('tableName') != 0 ? <Link to='/checkout' className='btn btn-danger col-12' enabled={false}>{loading ? 'Please wait...' : 'Checkout'}</Link> : null;
        }
    }

    return (
        <>

            {loading && <Loader />}
            <StyledCart>
                <div style={{ height: 50 }}></div>
                <div className='mobileCart'>
                    {renderButton(path)}
                </div>
                <SweetAlert
                    success
                    show={success}
                    title="Your Order Is Successfully Placed !"
                    btnSize="sm"
                    onConfirm={() => onConfirm('success')}
                />

                <DeleteConfirmationDialog
                    ref={deleteConfirmationDialog}
                    title="Are You Sure To Place Order?"
                    message=""
                    onConfirm={() => finallyPlaceOrder()}
                />
            </StyledCart>


        </>
    )
}
export default ViewCart;