import React, { useReducer } from "react";
import CartContext from "./CartContext";

const defaultCartState = {
    items: [],
    totalAmount: 0,
    allItems: [],
    filteredItems: [],
    customerInfo: { name: '', mobile: '', email: '', secretKey: '', otp: '' }
};

const cartReducer = (state, action) => {
    if (action.type === "ADD") {
        let updatedTotalAmount = state.totalAmount + parseFloat(action.item.mrp);

        let updatedItems = state.items;
        const existingCartItemIndex = state.items.findIndex((e) => e.productChildId === action.item.productChildId);


        if (existingCartItemIndex !== -1) {
            const existingCartItem = state.items[existingCartItemIndex];
            const updatedItem = {
                ...existingCartItem,
                qty: existingCartItem.qty + 1,
            };

            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        } else {
            updatedItems = updatedItems.concat(action.item);
        }
        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }
    if (action.type === "SUBSTRACT") {
        let updatedTotalAmount = state.totalAmount - parseFloat(action.item.mrp);

        let updatedItems = state.items;
        const existingCartItemIndex = state.items.findIndex((e) => e.productChildId === action.item.productChildId);


        if (existingCartItemIndex !== -1) {
            const existingCartItem = state.items[existingCartItemIndex];
            if (existingCartItem.qty == 1) {
                updatedItems = state.items.filter(
                    (item) => item.productChildId !== action.item.productChildId);
            }
            else {
                const updatedItem = {
                    ...existingCartItem,
                    qty: existingCartItem.qty - 1,
                };

                updatedItems = [...state.items];
                updatedItems[existingCartItemIndex] = updatedItem;
            }
        }
        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }

    if (action.type === "DEL") {
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.productChildId === action.productChildId
        );

        if (existingCartItemIndex !== -1) {
            const existingItem = state.items[existingCartItemIndex];
            const updatedTotalAmount = state.totalAmount - (existingItem.mrp * existingItem.qty);
            let updatedItems;

            updatedItems = state.items.filter(
                (item) => item.productChildId !== action.productChildId
            );

            return {
                ...state,
                items: updatedItems,
                totalAmount: updatedTotalAmount,
            };
        }
    }

    if (action.type === "CLEAR") {
        return {
            ...state,
            items: [],
            totalAmount: 0
        };
    }

    if (action.type === "addAll") {
        return {
            ...state,
            allItems: action.items
        };
    }

    if (action.type === "addFiltered") {
        return {
            ...state,
            filteredItems: action.items
        };
    }

    if (action.type === "updateCustomerInfo") {
        var currentCustomerInfo = state.customerInfo;

        currentCustomerInfo.name = action.customerInfo.name;
        currentCustomerInfo.mobile = action.customerInfo.mobile;
        currentCustomerInfo.email = action.customerInfo.email;
        currentCustomerInfo.secretKey = action.customerInfo.secretKey;
        currentCustomerInfo.otp = action.customerInfo.otp;

        return {
            ...state,
            customerInfo: currentCustomerInfo
        };
    }

    return defaultCartState;
};

const CartProvider = (props) => {
    const [cartState, cartDispatch] = useReducer(cartReducer, defaultCartState);

    const addItemToCartHandler = (item) => cartDispatch({ type: "ADD", item });
    const substractItemToCartHandler = (item) => cartDispatch({ type: "SUBSTRACT", item });
    const removeItemFromCartHandler = (productChildId) => cartDispatch({ type: "DEL", productChildId });
    const clearCartHandler = () => cartDispatch({ type: "CLEAR" });
    const setAllItemsCartHandler = (items) => cartDispatch({ type: "addAll", items });
    const setFilteredItemsCartHandler = (items) => cartDispatch({ type: "addFiltered", items });
    const setCustomerInfoHandler = (customerInfo) => cartDispatch({ type: "updateCustomerInfo", customerInfo });

    const cartContext = {
        items: cartState.items,
        totalAmount: cartState.totalAmount,
        allItems: cartState.allItems,
        filteredItems: cartState.filteredItems,
        customerInfo: cartState.customerInfo,
        addItem: addItemToCartHandler,
        substractItem: substractItemToCartHandler,
        removeItem: removeItemFromCartHandler,
        clearItems: clearCartHandler,
        setAllItems: setAllItemsCartHandler,
        setFilteredItems: setFilteredItemsCartHandler,
        setCustomerInfo: setCustomerInfoHandler
    };

    return (
        <CartContext.Provider value={cartContext}>
            {props.children}
        </CartContext.Provider>
    );
};

export default CartProvider;
