import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';

//Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import CartContext from "../store/CartContext";
import product from "../assets/images/category.jpg";
import Loader from '../component/Loader';

const Category = () => {
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(cartCtx.allItems);

    const parseParams = (querystring) => {
        console.log(querystring);
        // parse query string
        const params = new URLSearchParams(querystring);

        const obj = {};

        // iterate over all keys
        for (const key of params.keys()) {
            if (params.getAll(key).length > 1) {
                obj[key] = params.getAll(key);
            } else {
                obj[key] = params.get(key);
            }
        }

        return obj;
    };

    useEffect(() => {
        let params = parseParams(window.location.search);
        if (params.restaurantId !== undefined)
            localStorage.setItem('restaurantId', params.restaurantId);
        if (params.tableName !== undefined)
            localStorage.setItem('tableName', params.tableName);
        if (params.branchId !== undefined)
            localStorage.setItem('branchId', params.branchId);

        if (params.restaurantId == undefined) {
            if (localStorage.getItem('restaurantId') == undefined)
                localStorage.setItem('restaurantId', "150001");
        }
        if (params.tableName == undefined) {
            if (localStorage.getItem('tableName') == undefined)
                localStorage.setItem('tableName', "0");
        }
        if (params.branchId == undefined) {
            if (localStorage.getItem('branchId') == undefined || localStorage.getItem('branchId') == 0)
                localStorage.setItem('branchId', "1");
        }
    }, []);

    useEffect(() => {
        if (items !== undefined) {
            if (items.length == 0) {
                (async () => {
                    setIsLoading(true);
                    let branchId = localStorage.getItem('branchId');
                    if (localStorage.getItem('branchId') === "undefined" || localStorage.getItem('branchId') == 0)
                        branchId = 1;
                        

                    let restaurantId = localStorage.getItem('restaurantId');
                    if (localStorage.getItem('restaurantId') === "undefined")
                        restaurantId = "150001";

                   console.log("tenantId : " + restaurantId)     ;
                   console.log("branchId : " + branchId)     ;
                    await api.get('api/public/get-item-list?tenantId=' + restaurantId + '&branchId=' + branchId)
                        .then((response) => {
                            console.log(response.data);
                            if (response.status === 200) {
                                cartCtx.setAllItems(response.data);
                                const unique = [...new Set(response.data.map(item => item.categoryName))];
                                setCategories(unique);
                                setItems(response.data);
                            }
                        })
                        .catch(error => {
                        }).finally(() => {
                            setIsLoading(false);
                        });
                })();
            }
            else {
                if (categories.length == 0) {
                    const unique = [...new Set(cartCtx.allItems.map(item => item.categoryName))];
                    setCategories(unique);
                }
            }
        }
    });

    const OnCategoryClick = (categoryName) => {
        var filteredItems = items.filter(e => e.categoryName == categoryName);
        cartCtx.setFilteredItems(filteredItems);
        navigate('/menu');
    }


    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="title-heading mb-5">
                            <h3 className="text-white mb-1 fw-light text-uppercase">Select Category</h3>
                            <div className="title-border-color position-relative"></div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    {isLoading && <Loader />}
                    {
                        categories && categories.map((obj, key) => (
                            <Col lg={3} key={key}>
                                <div onClick={() => OnCategoryClick(obj)} style={{ flex: 1 }}>
                                    <Card style={{ marginTop: "10px" }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={
                                                items.filter(e => e.categoryName == obj)[0].imageUrl == null ?
                                                    product
                                                    :
                                                    items.filter(e => e.categoryName == obj)[0].imageUrl
                                            }
                                            alt="Item Category"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h7" component="div" align='center'>
                                                {obj}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </React.Fragment>
    );
};
export default Category;
