/**
 * Checkout Page
 */
import React, { Component } from 'react';

//Components
import CheckoutForm from './components/CheckoutForm';
import CheckoutItem from './components/CheckoutItem';
import { Container, Row, Col } from 'reactstrap';
// Card Component
import { RctCard, RctCardContent } from '../../component/RctCard';



class Checkout extends Component {
	render() {
		const { match } = this.props;
		return (
			<React.Fragment>
				<Container>
					<Row>
						<Col lg={12}>
							<div className="title-heading mb-5">
								<h3 className="text-white mb-1 fw-light text-uppercase">Menu</h3>
								<div className="title-border-color position-relative"></div>
							</div>
						</Col>
					</Row>
					<br />
					<div className="checkout-wrap">
						<RctCard customClasses="overflow-hidden">
							<RctCardContent noPadding>
								<div className="row no-gutters">
									<div className="col-lg-4 col-md-6 col-sm-12">
										<CheckoutItem />
									</div>
								</div>
							</RctCardContent>
						</RctCard>
					</div>
				</Container>
			</React.Fragment>
		)
	}
}
export default Checkout;