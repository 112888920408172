/**
 * Cart Component
 */
import React, { useState, Fragment, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Button from '@mui/material/Button';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { Badge } from 'reactstrap';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import CartContext from "../store/CartContext";

const Carts = () => {
	const cartCtx = useContext(CartContext);
	const cart = cartCtx.items;

	console.log("now : {0}", cartCtx.allItems)
	//Is Cart Empty
	const isCartEmpty = () => {
		if (cart.length === 0 || localStorage.getItem('tableName') == 0) {
			return true;
		}
	}

	const deleteItemFromCart = (productChildId) => {
		cartCtx.removeItem(productChildId);
	};
	return (
		<UncontrolledDropdown nav className="list-inline-item cart-dropdown" inNavbar>
			<DropdownToggle nav className="p-0">
				<Tooltip title="Shopping Cart" placement="bottom">
					<IconButton aria-label="bag">
						<ShoppingCartIcon className='cartButton' />
						<Badge
							color="success"
							className="badge-xs badge-top-right"
						>
							{cart.length}
						</Badge>
						<Badge
							pill
							color="danger"
							className="badge-xs badge-top-right"
						>
							Rs.{cartCtx.totalAmount}
						</Badge>
					</IconButton>
				</Tooltip>
			</DropdownToggle>
			<DropdownMenu>
				<div className="dropdown-content">
					<div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
						<span className="text-white font-weight-bold">Cart</span>
						<Badge color="warning">{cart.length} NEW</Badge>
					</div>
					{isCartEmpty() ? (
						<div className="text-center p-4">
							<span className="d-block font-3x mb-15 text-danger"><i className="zmdi zmdi-shopping-cart"></i></span>
							
							{localStorage.getItem('tableName') == 0 ? <h3>Menu is in view-only mode !</h3> : <h3>Your cart is empty !</h3>}
						</div>
					) : (
						<Fragment>
							<Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280} autoHide>
								<ul className="list-unstyled dropdown-list">
									{cart.map((cart, key) => (
										<li className="d-flex justify-content-between" key={key}>
											<div className="media overflow-hidden">
												{/* <div className="mr-15">
															<img src={cart.image} alt="products" className="media-object" width="63" height="63" />
														</div>  */}
												<div className="media-body">
													{/* <span className="fs-14 d-block">{cart.itemCode}</span> */}
													<span className="fs-12 d-block text-muted">{cart.itemName}</span>
													{/* <span className="fs-12 d-block text-muted">{cart.brand}</span> */}
												</div>
											</div>
											<div className="text-center d-flex">
												<span className="text-muted fs-12 d-block mb-10">Rs. {cart.mrp} X {cart.qty}</span>
												<a href="#"
													className="hover-close"
													onClick={() => deleteItemFromCart(cart.productChildId)}>
													<CancelPresentationIcon color="error" />
												</a>
											</div>
										</li>
									))}
								</ul>
							</Scrollbars>
							<div className="dropdown-foot d-flex justify-content-between align-items-center p-2 bg-white rounded-bottom">
								<div className='row'>
									<div className='col-6'>
										<Button
											variant="raised"
											component={Link}
											to={`/cart`}
											color="primary"
											className="mr-10 btn-xs bg-primary d-flex text-white"
										>
											View Cart
										</Button>
									</div>
									<br />
									<div className='col-6'>
										<Button
											variant="raised"
											component={Link}
											to={`/checkout`}
											color="warning"
											className="mr-10 btn-xs bg-danger d-flex text-white"
										>
											Checkout
										</Button>
									</div>
									<div className='col-12'>
										<div className='pl-2 pt-2'>
											<span className="fw-normal text-dark d-flex font-weight-bold font-xs">
												Total Rs. {cartCtx.totalAmount.toFixed(2)}
											</span>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)
					}
				</div>
			</DropdownMenu>
		</UncontrolledDropdown>
	);

}

export default Carts;
