import React, { useContext } from 'react';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import CartContext from "../../../store/CartContext";

const BillingForm = () => {
   const cartCtx = useContext(CartContext);
   const onChangeCustomerInfo = (key, value) => {
      var cinfo = cartCtx.customerInfo;
      if (key == "name")
         cinfo.name = value;
      else if (key == "mobile")
         cinfo.mobile = value;
      else if (key == "email")
         cinfo.email = value;

      cartCtx.setCustomerInfo(cinfo);
   }

   console.log(cartCtx);

   return (
      <Form>
         <FormGroup row>
            <Col sm={6}>
               <Label for="firstName">Name</Label>
               <Input
                  value={cartCtx.customerInfo.name}
                  type="text"
                  name="first name"
                  onChange={(e) => onChangeCustomerInfo('name', e.target.value)}
               />
            </Col>
         </FormGroup>
         <FormGroup row>
            <Col sm={6}>
               <Label for="contactNumber">Mobile</Label>
               <Input
                  type="tel"
                  name="number"
                  onChange={(e) => onChangeCustomerInfo('mobile', e.target.value)}
               />
            </Col>
         </FormGroup>
         <FormGroup row>
            <Col sm={6}>
               <Label for="emailId">Email</Label>
               <Input
                  type="email"
                  name="mail"
                  className="mb-4 mb-sm-0"
                  onChange={(e) => onChangeCustomerInfo('email', e.target.value)}
               />
            </Col>
         </FormGroup>
      </Form>
   );
}

export default BillingForm;