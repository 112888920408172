import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/theme.scss";

import Layout2 from './component/Layout2';
import Menu from './component/Menu';

import CartProvider from "./store/CartProvider";
import Category from "./component/Category";
import Cart from "./component/cart/Carts";
import RequestOTP from "./component/otp/RequestOTP";
import Checkout from "./component/checkout";
import MyOrder from "./component/MyOrder";
import VerifyOTP from "./component/otp/VerifyOTP";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <BrowserRouter>
      <CartProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Layout2 />}>
            <Route index element={<Category />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/my-order" element={<MyOrder />} />
            <Route path="/request-otp" element={<RequestOTP />} />
            <Route path="/verify-otp" element={<VerifyOTP />} />
          </Route>
        </Routes>
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;
