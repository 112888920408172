import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import {
  Container,
  Row,
  Toast
} from "reactstrap";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Import Logo
import logo from "../assets/images/zooki.png";
import Cart from './Cart'
import CartContext from "../store/CartContext";
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../component/Loader';
const ITEM_HEIGHT = 48;
const StyledButton = styled.button`
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #4d1601;
    color: white;
    padding: 0.75rem 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 0.5rem;
        padding: 0.5rem 1.2rem;
    }

    &:hover,
    &:active {
        background-color: #2c0d00;
    }

    .icon {
        width: 1.35rem;
        height: 1.35rem;
        margin-right: 0.5rem;
    }

    .badge {
        background-color: #b94517;
        padding: 0.25rem 1rem;
        border-radius: 25px;
        margin-left: 1rem;
        font-weight: bold;
    }

    &:hover .badge,
    &:active .badge {
        background-color: #92320c;
    }

    &.bump {
        animation: bump 300ms ease-out;
    }

    @keyframes bump {
        0% {
            transform: scale(1);
        }
        10% {
            transform: scale(0.9);
        }
        30% {
            transform: scale(1.1);
        }
        50% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const Navbar = ({ children }) => {
  const navigate = useNavigate();
  const [navclass, setnavclass] = useState("");
  const [btnIsHighlighted] = useState(true);
  const cartCtx = useContext(CartContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const TenantLogo = () => {
    const restaurantId = localStorage.getItem('restaurantId');
    const logoPath = restaurantId ? require(`../assets/images/${restaurantId}.png`) : logo;
  
    try {
      return (
        <div>
          <img src={logoPath} alt={`${restaurantId} LOGO`} height="60" />
        </div>
      );
    } catch (error) {
      console.error('Error loading logo:', error);
      return <div>Error loading logo</div>;
    }
  };

  const onMyOrderClicked = () => {
    console.log("my order clicked ");
    navigate('/my-order');
  }

  const onCallWaiterClicked = () => {

    var newData = {
      jsonContent: "[]",
      tableNo: localStorage.getItem('tableName'),
      type: "CallWaiter",
      tenantId: localStorage.getItem('restaurantId'),
      uid: localStorage.getItem('uid')
    }

    setLoading(true);
    api.post('api/rest/scan-2-order/post-scan-2-order', newData, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
      .then(async (response) => {
        if (response.status == 200) {
          toast("Call waiter order has been sent to concern department !");
        } else {
          alert("Something went wrong !");
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error.response.data));
        toast.error(error.response.data);
      }).finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavclass("nav-sticky");
    } else {
      setnavclass("");
    }
  }

  return (
    <React.Fragment>
      <nav className="navbar-custom navclass fixed-top" id="navbar">
        <Container>

          <Row>
            <div className="col-lg-6 col-6">
              <Link className="navbar-brand logo" to="/">
                {/* <img
                  src={logo}
                  alt="scan2order"
                  height="20"
                /> */}
                <TenantLogo />
              </Link>
            </div>
            <div className='col-lg-4 col-4'>
              <Cart />
            </div>
            {/* <div className="shopbutton col-lg-2 col-2">
              <Link to="/">
                Menu
              </Link>
            </div> */}
            <div className='col-lg-2 col-2'>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  {(localStorage.getItem('token') == null || localStorage.getItem('token') == undefined) ?
                    <MenuItem key='Explore Menu' onClick={(e) => { navigate('/') }}>Explore Menu</MenuItem>
                    :
                    <>
                      <MenuItem key='MyOrder' onClick={(e) => { handleClose(); onMyOrderClicked() }}>My Order</MenuItem>
                      <MenuItem key='CallWaiter' onClick={(e) => { handleClose(); onCallWaiterClicked() }}>Call Waiter</MenuItem>
                      <MenuItem key='Logout' onClick={(e) => {
                        setLoading(true);
                        localStorage.removeItem('token');
                        toast("You are logged out", { position: "top-right" });
                        setLoading(false); navigate('/')
                      }}>Logout</MenuItem>
                    </>
                  }
                </Menu>
              </div>
            </div>
          </Row>
        </Container>
        {loading && <Loader />}
      </nav>
      <div>

        {children}
      </div>
    </React.Fragment >
  );
}

export default Navbar;