import React, { useContext, useState, useEffect } from 'react';
import CartContext from "../../store/CartContext";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import OTPInput, { ResendOTP } from "otp-input-react";

const VerifyOTP = () => {
   const cartCtx = useContext(CartContext);
   const onChangeCustomerInfo = (key, value) => {
      var cinfo = cartCtx.customerInfo;
      if (key == "name")
         cinfo.name = value;
      else if (key == "mobile")
         cinfo.mobile = value;
      else if (key == "email")
         cinfo.email = value;
      else if (key == "otp")
         cinfo.otp = value;

      cartCtx.setCustomerInfo(cinfo);
   }



   const [OTP, setOTP] = useState("");
   useEffect(() => {
      onChangeCustomerInfo('otp', OTP);
   }, [OTP])
   return (
      <React.Fragment>
         <div style={{ marginLeft: 70, marginTop: 150, textAlign: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <OTPInput
               value={OTP}
               onChange={setOTP}
               autoFocus
               OTPLength={4}
               otpType="number"
               disabled={false}
               style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center' }}
            />
            {/* <ResendOTP maxTime={120} handelResendClick={() => console.log("Resend clicked")} /> */}
         </div>
      </React.Fragment >
   );
}

export default VerifyOTP;