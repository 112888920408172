import { Button, Input } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components";
import CartContext from "../../../store/CartContext";

const StyledItem = styled.li`
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;

    h3 {
        margin: 0 0 0.25rem 0;
        font-size: 20px;
        text-transform: capitalize;
    }

    h3.description {
        font-style: italic;
    }

    h3.price {
        margin-top: 0.25rem;
        font-weight: 500;
        color: #ad5502;
        font-size: 20px;
        font-size: 1.25rem;
    }

    .addToCart{
        display: -webkit-inline-box;
        text-align: center;
    }

    @media (max-width: 768px) {
        h3 {
            font-size: 0.8rem;

            &.price {
                font-size: .9rem;
            }
        }
    }
`;

const StyledForm = styled.form`
    text-align: right;

    button {
        font: inherit;
        cursor: pointer;
        background-color: #8a2b06;
        border: 1px solid #8a2b06;
        color: white;
        padding: 0.25rem 2rem;
        border-radius: 20px;
        font-weight: bold;

        &:hover,
        &:active {
            background-color: #641e03;
            border-color: #641e03;
        }

        @media (max-width: 768px) {
            font-size: 0.7rem;
            padding: 0.5rem 1.2rem;
        }
    }
`;



const MealItem = (props) => {
    const cartCtx = useContext(CartContext);
    const addToCartHandler = (e) => {
        e.preventDefault();
        cartCtx.addItem(props.item);
    };
    const substactToCartHandler = (e) => {
        e.preventDefault();
        cartCtx.substractItem(props.item);
    };


    return (
        <StyledItem>
            <div>
                <h3>{props.item.itemName}</h3>
                <h3 className="price">Rs.{props.item.mrp}</h3>
            </div>
            <div>
                <StyledForm className="addToCart">
                    {
                        cartCtx.items.findIndex(e => e.productChildId == props.item.productChildId) == -1
                            ?
                            <Button variant="contained" color="secondary" onClick={(e) => addToCartHandler(e)}>Add</Button>
                            :
                            <>
                                <button onClick={(e) => addToCartHandler(e)}>+</button>
                                <Input type='text'
                                    value={cartCtx.items.filter(e => e.productChildId === props.item.productChildId)[0].qty}
                                    inputProps={{ style: { textAlign: 'center', width: 20 } }} readOnly />
                                <button onClick={(e) => substactToCartHandler(e)}>-</button>
                            </>
                    }
                </StyledForm>
            </div>
        </StyledItem>
    );
};

export default MealItem;
