 
 import React, { useContext } from 'react'; 
  import IconButton from '@mui/material/IconButton'; 
 import DeleteIcon from '@mui/icons-material/Delete';
 import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
 import { Link } from 'react-router-dom';
 import { Button,Input } from "@mui/material"; 
 import styled from "styled-components";
 import CartContext from "../../store/CartContext";
 import { Container, Row, Col } from 'reactstrap';
 // Card Component
 import { RctCard, RctCardContent } from '../RctCard';
 import product from "../../assets/images/item.png"; 

 const StyledItem = styled.li`
 display: flex;
 justify-content: space-between;
 margin: 1rem;
 padding-bottom: 1rem;
 border-bottom: 1px solid #ccc;

 h3 {
     margin: 0 0 0.25rem 0;
     font-size: 20px;
     text-transform: capitalize;
 }

 h3.description {
     font-style: italic;
 }

 h3.price {
     margin-top: 0.25rem;
     font-weight: 500;
     color: #ad5502;
     font-size: 20px;
     font-size: 1.25rem;
 }

 .addToCart{
     display: -webkit-inline-box;
     text-align: center;
 }

 @media (max-width: 768px) {
     h3 {
         font-size: 0.9rem;

         &.price {
             font-size: 1.1rem;
         }
     }
 }
`;

const StyledForm = styled.form`
 text-align: right;

 button {
     font: inherit;
     cursor: pointer;
     background-color: #8a2b06;
     border: 1px solid #8a2b06;
     color: white;
     padding: 0.25rem 2rem;
     border-radius: 20px;
     font-weight: bold;

     &:hover,
     &:active {
         background-color: #641e03;
         border-color: #641e03;
     }

     @media (max-width: 768px) {
         font-size: 0.7rem;
         padding: 0.5rem 1.2rem;
     }
 }
`;


 
 const Carts =(props) =>{
   const cartCtx = useContext(CartContext);
   const cart =cartCtx.items;
   console.log(cart);
 
   const addToCartHandler = (e,item) => {
      e.preventDefault();
      cartCtx.addItem(item);
  };
  const substactToCartHandler = (e,item) => {
      e.preventDefault();
      cartCtx.substractItem(item);
  };

  const deleteItemFromCart= (productChildId) =>{
   cartCtx.removeItem(productChildId);
};
 
    //Get Total Price
    const getTotalPrice = () => {  
       return cartCtx.totalAmount.toFixed(2);
    }
 
    //Is Cart Empty
    const isCartEmpty = () => {
      if (cart.length === 0) {
			return true;
		}
    }
 
 
    return (
      <React.Fragment> 
          <Container>
          <Row>
            <Col lg={12}>
                  <div className="title-heading mb-5">
                     <h3 className="text-white mb-1 fw-light text-uppercase">Menu</h3>
                     <div className="title-border-color position-relative"></div>
                  </div>
            </Col>
         </Row>
         <br />
      <div className="cart-wrapper"> 
         <RctCard>
            <RctCardContent noPadding>
               <div className="checkout-item-wrap">
                  { !isCartEmpty() ? cart.map((cart, key) => (
                     <div className='cartControl col-lg-6 col-12'>
                        <div  key={key}>
                        <div className='row'>
                           <div className='col-4'>
                              <div className='mb-2'>
                                 {/* <img src={cart.image} alt="products" className="media-object" width="100" height="100" /> */}
                                 <img src={product} alt="products" className="media-object" width="100" height="100" />
                                 {/* <span className="font-weight-bold w-15">Quantity</span> */}
                              </div>                              
                           </div>
                           <div className='col-8'>
                              <span>{cart.itemName}</span>
                              <div className='d-flex my-3'> 
                                 <div>Price : ₹ {cart.mrp}</div>  
                              </div>
                              <div className='d-flex my-3'>  
                                 <div>Total : ₹ {parseFloat(cart.mrp) * parseFloat(cart.qty)}</div>
                                 
                              </div>
                            
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-6'>
                              <StyledForm className="addToCart">
                                 <button onClick={(e)=>addToCartHandler(e,cart)}>+</button>   
                                 <Input type='text'
                                 value={cartCtx.items.filter(e=>e.productChildId === cart.productChildId)[0].qty} 
                                 inputProps={{ style: {textAlign: 'center',width:20}  }}  readOnly                         / >               
                                 <button  onClick={(e)=>substactToCartHandler(e,cart)}>-</button>                             
                              </StyledForm>
                            </div>
                            <div className='col-6'>
                              <button className='btn btn-default btn-sm' onClick={() => deleteItemFromCart(cart.productChildId)}>
                                    Remove
                              </button>
                            </div>
                        </div>
                        </div>
                     </div>
                  )) :
                  <>
                     <span className="d-block font-5x mb-30 text-danger"><i className="zmdi zmdi-shopping-cart"></i></span>
                     <span className="mb-20 font-3x">Your cart is empty !</span>
                  </>                     
                  }
                  <div className='col-12'>
                     <div className='col-lg-5 col-12'>
                        <ul className='d-flex justify-content-between align-items-center list-unstyled w-100'>
                           <li><span className="font-weight-bold">Order Value</span></li>
                           <li><span className="font-weight-bold">{getTotalPrice()}</span></li> 
                        </ul>
                     </div>
                  </div>
               </div>
            </RctCardContent>
         </RctCard>
      </div>
      </Container>
      </React.Fragment>
   )
 } 
 
 export default Carts;