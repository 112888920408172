import React,{useEffect} from "react";
import Navbar from "./Navbar"; 
import Footer from "./Footer/Footer"; 
import { Outlet } from "react-router-dom";

const Layout2 = () => {   

 
 
  return (
    <React.Fragment>
      <Navbar />       
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
export default Layout2;
