import React from "react";
import AvailableMeals from "./AvailableMeals"; 

const Meals = () => {
    return (
        <> 
            <AvailableMeals />
        </>
    );
};

export default Meals;
