import React, { useEffect, useState,useContext } from "react";
import styled from "styled-components";  
import MealItem from "./meal-item/MealItem";
import CartContext from "../../store/CartContext";
import { RctCard, RctCardContent } from '../RctCard';

const MealSection = styled.section`
    max-width: 100%;
    width: 100%;
    margin: 2rem auto;
    animation: meals-appear 1s ease-out forwards;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    @keyframes meals-appear {
        from {
            opacity: 0;
            transform: translateY(3rem);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const AvailableMeals = () => {
    const cartCtx = useContext(CartContext);
    const items=cartCtx.filteredItems;
  
    return (
        <MealSection>
             <RctCard>
                <ul>{items && items.map((meal) => (         
                    <MealItem key={meal.productChildId}
                            item={meal}
                        /> 
                    ))
                }</ul>
             </RctCard>
        </MealSection>
    );
};

export default AvailableMeals;
