/**
 * Checkout Form Component
 */
import React, { Component } from 'react'; 
// import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

//Component 
import BillingForm from './BillingForm'; 

function TabContainer({ children, dir }) {
   return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
         {children}
      </Typography>
   );
}

class CheckoutForm extends Component {
   state = {
      value: 0,
   };

   handleChange = (event, value) => {
      this.setState({ value });
   };

   render() {
      const { value } = this.state;
      return (
         <div className="checkout-form-wrap">
            <div>
               {/* <AppBar position="static" color="default"> */}
                  <BillingForm onComplete={() => this.setState({ value: 1 })} />
               {/* </AppBar> */}              
            </div>
         </div>
      );
   }
}

export default CheckoutForm;
