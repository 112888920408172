import React, { useContext, useRef, useEffect } from 'react'; 
import CartContext from "../../store/CartContext";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
const RequestOTP = () => {
   const mobileRef = useRef();
   const cartCtx = useContext(CartContext);
   const onChangeCustomerInfo = (key, value) => {
      var cinfo = cartCtx.customerInfo;
      if (key == "name")
         cinfo.name = value;
      else if (key == "mobile")
         cinfo.mobile = value;
      else if (key == "email")
         cinfo.email = value;
      else if (key == "secretkey")
         cinfo.secretKey = value;

      cartCtx.setCustomerInfo(cinfo);
   }

   useEffect(() => {
      mobileRef.current.focus();
   })

   return (
      <React.Fragment>
         <Box component="form">
            <div style={{ marginTop: 150, textAlign: 'center' }}>
               <TextField
                  label="Enter your mobile number" variant="standard" inputRef={mobileRef} type='number'
                  required={true}
                  value={cartCtx.customerInfo.mobile}
                  onChange={(e) => onChangeCustomerInfo('mobile', e.target.value)}
                  inputProps={{ maxLength: 10 }}
               />
            </div>
         </Box>
      </React.Fragment >
   );
}

export default RequestOTP;