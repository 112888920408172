import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App'; 
import reportWebVitals from './reportWebVitals';
import {  BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(   
      <App />     
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();