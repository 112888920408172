import React, { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CartContext from "../../../store/CartContext";

const CheckoutItem = () => {
   const cartCtx = useContext(CartContext);
   const cart = cartCtx.items;

   //Get Total Price
   const getTotalPrice = () => {
      return cartCtx.totalAmount.toFixed(2);
   }

   //Is Cart Empty
   const isCartEmpty = () => {
      if (cart.length === 0) {
         return true;
      }
   }

   return (
      <div className="checkout-item-wrap">
         {isCartEmpty() ? (
            <div className="text-center pt-4 pb-4">
               <h3>No item found !</h3>
            </div>
         ) : (
            <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={450} autoHide>
               <ul className="">
                  {cart.map((cart, key) => (
                     <li className="border-bottom d-flex justify-content-between mb-10" key={key}>
                        <div className="media overflow-hidden">
                           <div className="mr-15">
                              {/* <img src={cart.image} alt="products" className="media-object" width="63" height="63" /> */}
                           </div>
                           <div className="">
                              <span><small><b>{cart.itemName}</b></small></span>
                              <br />
                              <small className='text-muted'>  Rs. : {cart.mrp} * {cart.qty}</small>
                           </div>
                        </div>
                        <div style={{ marginRight: 50 }}>
                           <span><small><b>{cart.qty * cart.mrp}</b></small></span>
                        </div>
                     </li>
                  ))}
               </ul>
               <ul>
                  <li className="border-bottom d-flex justify-content-between pt-2 pb-2">
                     <div className="media overflow-hidden">
                        <div className="mr-15">

                        </div>
                        <div className="media-body text-truncate">

                        </div>
                     </div>
                     <div className="w-10">

                     </div>
                     <div className="w-15">
                        <span className="text-muted fs-12 d-block mb-10">Total</span>
                     </div>
                     <div style={{ marginRight: 50 }}>
                        <span className="font-weight-bold">{getTotalPrice()}</span>
                     </div>
                  </li>
               </ul>
            </Scrollbars>
         )
         }
      </div >
   )


}

export default CheckoutItem;