import axios from 'axios';


export default
   axios.create({
      //baseURL: 'http://192.168.29.99:58375/',
      // baseURL: 'http://localhost:58380/',
      baseURL: 'https://billtronapi.hellopatna.com/',
      timeout: 80000,
      headers: {
         'Authorization': 'Bearer ' + localStorage.getItem('token'),
         'Content-Type': 'application/json'
      }
   });